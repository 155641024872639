<template>
  <div class="body-box">
    <!--    导航盒子-->
    <nav-box></nav-box>
    <!--<div class="box">
      <i class="el-icon-close" @click="quit"></i>
    </div>-->
    <el-card class="box-card">
      <!-- logo部分 -->
      <div>
        <span>欢迎注册!</span>
      </div>

      <!-- 步骤条 -->
      <el-steps :active="activeIndex" finish-status="success" align-center>
        <el-step title="注册用户"></el-step>
        <el-step title="填写基础信息"></el-step>
        <el-step title="完善企业资料"></el-step>
        <el-step title="资质审核"></el-step>
      </el-steps>

      <div v-for="(item, index) in certifications" :key="index">
        <certification :certifications='certifications' :index="index"></certification>
      </div>

      <el-col :span="11">
        <div class="el-icon-plus add-box" @click="addFromBox"></div>
      </el-col>

      <el-col :span="24">
        <el-button class="complete" @click="submitLogin">提交审核</el-button>
      </el-col>

    </el-card>
  </div>
</template>
<script>
import certification from '@/components/common/certification'
import NavBox from '@/components/common/navBox'

export default {
  data () {
    return {
      certifications: [{
        hasModify: false,
        name: '商业通用条款',
        brief: '签字盖章上传',
        download: '',
        filename: '沃莱迪供应商通用条款.doc',
        md5: [],
        fileList: []
      }, {
        hasModify: false,
        name: '廉洁协议',
        brief: '签字盖章上传',
        download: '',
        filename: '廉洁协议.doc',
        md5: [],
        fileList: []
      }],
      activeIndex: 3,
      rules: {},
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  mounted () {
  },
  created() {
    this.certifications[0].download = `${this.getaway.redirect}srm/file/b089122d849c1bfbd9f182e7e375e25e/download`
    this.certifications[1].download = `${this.getaway.redirect}srm/file/595744df3c5cfa3a33d7b5ece1b7f975/download`
  },
  methods: {
    // 点击按钮新增表单
    addFromBox () {
      const addBox = {
        hasModify: true,
        md5: [],
        fileList: []
      }
      this.certifications.push(addBox)
    },
    quit () {
      this.$router.push('/index')
    },

    // 提交审核
    async submitLogin () {
      if (this.certifications[0].md5.length === 0 || this.certifications[1].md5.length === 0) {
        this.$message({
          message: '请上传商业通用条款/廉洁协议!',
          type: 'error'
        })
        return
      }
      for (let i = 0; i < this.certifications.length; i++) {
        this.certifications[i].md5 = this.certifications[i].md5.join(',')
        delete (this.certifications[i].fileList)
        delete (this.certifications[i].hasModify)
      }

      const auditlist = {
        licenseList: this.certifications,
        srmSupplierId: sessionStorage.getItem('distId')
      }

      const auditlists = JSON.stringify(auditlist)

      await this.axios
        .post(`${this.getaway.redirect}srm/supplier_licenses/addBatch`, auditlists)
        .then((res) => {
          if (Number(res.code) === 0) {
            this.$router.push('/achieve')
          }
        })
    }
  },
  name: 'certifications',
  components: {
    NavBox,
    certification
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-step__head.is-success {
  color: #3150E9;
}

/deep/ .el-step__title.is-success {
  color: #3150E9;
}

/deep/ .el-step__head.is-success {
  border-color: #3150E9;
}

.box {
  width: 1200px;
  margin: 20px auto;
  box-sizing: border-box;
  text-align: right;
  color: #ccc;

  > i {
    cursor: pointer;
  }
}

.el-card {
  width: 70%;
  margin: 50px auto;
  padding-bottom: 20px;
}

.el-steps {
  width: 80%;
  margin: 20px auto;
}

.form-box {
  width: 80%;
  margin: 0 auto;
}

.note {
  width: 50%;
  margin-right: 20px;
}

/deep/ .verify {
  position: relative;

  .el-input__inner {
    width: 60%;
  }

  .pic {
    position: absolute;
    top: 0;
    right: 22px;
    width: 120px;
    height: 100%;
    border: 1px solid #dcdfe6;
  }
}

.btn-box {
  // display: block;
  margin: 20px auto;
  width: 200px;
}

.note-box {
  width: 40%;
  padding: 12px 0;
}

.demo-form-box {
  border: 1px solid #ccc;
  padding: 40px 30px;
  border-radius: 20px;
  margin: 10px;
}

.upload-demo {
  display: inline-block;
  width: 25%;
  margin-left: 5%;
}

.add-box {
  width: 100%;
  height: 188px;
  line-height: 188px;
  text-align: center;
  font-size: 158px;
  color: #CCCCCC;
  margin-top: 40px;
}

.complete {
  display: block;
  margin: 20px auto;
  letter-spacing: 5px;
}
</style>
